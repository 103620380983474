//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDictionary,
         getDictFLJ,
         getStateRules,
         getElections,
         getUpcomingElections,
         getLastElection,
         getDeadlines,
         getNewVfaDate } from '~/utils/butterUtils'

import { getVFAParameters } from '~/utils/VFAParameters'

export default {
  data () {
    return {
      isActiveSSN: false
    }
  },
  computed: {
    process () {
      return { 'url': process.env.url, 'stage': process.env.stage }
    },
    showTestData () {
      return this.$store.state.showTestData
    },
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    lang () {
      return this.$i18n.locale.toLowerCase()
    },
    selectedState () {
      return this.$store.state.selectedState
    },
    voterType () {
      return this.$store.state.voterType
    },
    stateRules () {
      if (this.selectedState) {
        let scr1 = this.butterStateVotingRules.find(x => x.stateid.toLowerCase().slice(0, 2) === this.selectedState.toLowerCase())
        return getStateRules(scr1, this.lang, this.$store.state.showDictionaryKeys)
      } else {
        return undefined
      }
    },
    dict () {
      if (this.butterDictionary) {
        return getDictionary(this.butterDictionary, this.lang, 'd', this.$store.state.showDictionaryKeys)
      } else {
        return { "ERROR": "error"}
      }
    },
    dictwys () {
      if (this.butterDictionaryWYSIWYG) {
        return getDictionary(this.butterDictionaryWYSIWYG, this.lang, 'w', this.$store.state.showDictionaryKeys)
      } else {
        return { "ERROR": "error"}
      }
    },
    butterStateVotingRules () {
      return this.$store.state.butterStateVotingRules
    },
    butterDictionary () {
      return this.$store.state.butterDictionary
    },
    butterDictionaryWYSIWYG () {
      return this.$store.state.butterDictionaryWYSIWYG
    },

    /**
     * 2024-08-30 John Yee
     * GitHub Issue #2126 Page suppression test 
     * 
     * per Karen Frankenstein
     * Hi john, please do test this. We have massive drop off problems on vfa and these extra pages appear to be the culprit. 
     */
    /**
     * 2024-08-30 John Yee
     * GitHub Issue #2126 Page suppression test 
     * 
     * had to add the following to the import from '~/utils/butterUtils'
     *   getElections,
         getUpcomingElections,
         getLastElection,
         getDeadlines,
         getNewVfaDate
     */
    VFAParameters () {
      return getVFAParameters(this.$store)
    },
    FWAB_TRIGGER_DEFAULT () {
      return this.VFAParameters.FWAB_TRIGGER_DEFAULT
    },
    LOCAL_STORAGE_SELECTED_STATE_TIME_TO_LIVE () {
      return this.VFAParameters.LOCAL_STORAGE_SELECTED_STATE_TIME_TO_LIVE
    },
    voterRegistration () {
      return this.$store.state.voterIsRegistered
    },
    elections () {
      const scr0 = this.butterStateElections
      if (scr0) {
        const scr1 = scr0.filter(item => item.stateid.toLowerCase().slice(0, 2) === this.selectedState.toLowerCase())
        return getElections(scr1, this.FWAB_TRIGGER_DEFAULT, this.lang)
      } else {
        return []
      }
    },
    upcomingElections () {
      /** 2023-11-20 John Yee
       *  per conversation with Heidi
       *  all elections should be visible to any voter
       *  i.e. no filtering based on military or registration status
       *
       *  but, if she changes her mind, then here is where we filter the elections ...
       *    return getUpcomingElectionsFiltered (this.elections, stateRules.militaryseparate, stateRules.votertypesused, stateRules.usesvoterisregistered, this.voterType, this.voterIsRegistered)
       * 
       *  note: militaryseparate to be removed eventually
       */
      return getUpcomingElections (this.elections)
    },
    deadlineElection () {
      // normally you use ...
      //    return getDeadlineElection (this.upcomingElections, this.voterIsRegistered)
      // but getLastElection is a special case for this page
      return getLastElection (this.upcomingElections) 
    },
    deadlines () {
      let d = getDeadlines (this.deadlineElection, this.voterType, this.voterIsRegistered)
      return d
    },
    daysToFWABDeadline () {
      return this.deadlines.FWAB.daysToFWAB
    },
    butterStateElections () {
      return this.$store.state.butterStateElections
    },
    /**
     * 2024-08-30 John Yee
     * GitHub Issue #2126 Page suppression test 
     * 
     * end of newly inserted code
     */

  },
  methods: {
    getDictFLJ(dictItem, dict) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictFLJ(dictItem, dict)" in the return statement is really
       * the function getDictFLJ(dictItem, dict) that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictFLJ(dictItem, dict)
    },
  },
  mounted() {
    /**
     * 2024-08-30 John Yee
     * GitHub Issue #2126 Page suppression test 
     * 
     * per Karen Frankenstein
     * Hi john, please do test this. We have massive drop off problems on vfa and these extra pages appear to be the culprit. 
     */

    if (this.daysToFWABDeadline>0) {
      this.$store.commit('saveVoterFWAB', true)
    } else {
      this.$store.commit('saveVoterFWAB', false)
    }

    let currentSelectedState = this.selectedState
    let lsObj = JSON.stringify(Object.assign({}, this.$store.state.votingState, {'selectedState': currentSelectedState}, {'expiryTime': getNewVfaDate().getTime()+this.LOCAL_STORAGE_SELECTED_STATE_TIME_TO_LIVE*1000}))
    localStorage.setItem('localstoragevotingstate', lsObj)
    sessionStorage.removeItem('sessionstoragephones')
    this.$store.commit('clearVoterData')
    this.$store.dispatch('userdata/clearVoterData')
    this.$store.commit('saveSelectedState', currentSelectedState)
  },
}
